<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Nueva Inscripción</template>
    <template #body>
      <form @submit.prevent="inscripcion">
        <FormText
        label="Nombre del participante"
        icon="fa-solid fa-user"
        v-model="form.first_name"
        disabled
      />
        <FormSelect
        v-model="form.perro"
        label="Perro a competir"
        :options="perros"
        icon="fa-solid fa-dog"
        />
        <FormSelect
        v-model="form.torneo"
        label="Torneos"
        :options="torneos"
        icon="fa-solid fa-trophy"
        />
        <FormText
          label="Carta Presentacion"
          icon="fa-solid fa-paragraph"
          :textarea="true"
          v-model="form.presentacion"
          />
      </form>
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="inscripcion">Inscribirse</button>
    </template>
  </vue-modal>
</template>
<script>
import FormSelect from "@/components/Form/FormSelect.vue";
import FormText from "@/components/Form/FormText.vue";
export default {
  components: {
    FormSelect,
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    perros(){
      return [{value:1, name:"Firulais"},{value:1, name:"Rocko"}]
    },
    torneos(){
      return [{value:1, name:"Caninos"},{value:2, name:"Perreo"}]
    }
  },
  data: () => ({
    form:{
      first_name: null,
      perro: null,
      torneo: null,
      presentacion: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    inscripcion() {
      this.close();
      this.$toast.success("Formulario Enviado a Ningun Lado");
    },
  },
};
</script>
<style></style>
