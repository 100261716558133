<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Inscripción a Torneos</template>
        <div class="contenedor-selectores">
          <FormSelect
            @selectChange="get_calendarios"
            class="selector"
            label="Evento al que se inscribe"
            :options="torneos"
            icon="fa-solid fa-trophy"
          />

          <FormSelect
            @selectChange="updateCalendarios"
            class="selector"
            label="Nombre de la fecha"
            :options="calendarios"
            icon="fa-regular fa-calendar"
          />
        </div>
        <vue-table
          class="marg-b-2"
          v-if="inscribirTable.headers"
          :values="perros"
          :headers="inscribirTable.headers"
          :options="inscribirTable.options"
          :actions="inscribirTable.actions"
          ref="vtable"
          :search_placeholder="'Buscar por codigo'"
          @inscripcion="inscribir"
          @changed="findPerros"
          :search_min_length="5"
          :spinnerClass="'mpvtSpinner'"
          :isFetching="isFetchingInscribir"
        />

        <h5>Ejemplares inscriptos</h5>
        <vue-table
          class="marg-b-2"
          v-if="inscriptosTable.headers"
          :values="perros_inscriptos"
          :headers="inscriptosTable.headers"
          :options="inscribirTable.options"
          ref="vtable"
          :search_placeholder="'Buscar perro por codigo'"
          @changed="findInscriptos"
          :isFetching="isFetchingInscriptos"
        />
      </Widget>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import Widget from "@/components/Widget.vue";
import FormText from "@/components/Form/FormText.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import inscripcionModal from "./inscripcionModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Widget,
    inscripcionModal,
    FormText,
    FormSelect,
  },

  data() {
    return {
      showModal: false,
      selItem: null,
      torneo: null,
      calendario_id: null,
      isFetchingInscribir: false,
      isFetchingInscriptos: false,
      cached_search_inscriptos: "",
      cached_search_inscribir: "",

      inscribirTable: {
        options: {
          /// Go to OPTIONS SECTION for explanation
        },
        actions: [],
        headers: [
          {
            title: "can_nombre",
            mask: "Can Nombre",
            sortable: true,
            searchable: true,
          },
          {
            title: "can_codigo",
            mask: "Can Numero",
            sortable: true,
            searchable: true,
          },
          {
            title: "can_sexo",
            mask: "Can Sexo",
            sortable: true,
          },
          {
            title: "fecha_nacimiento",
            mask: "Fecha de Nacimiento",
            sortable: true,
          },
          {
            title: "can_peludo",
            mask: "Pelo Largo",
            sortable: true,
          },
          {
            title: "duenio_nombre",
            mask: "Dueño",
            sortable: true,
            searchable: false,
          },
          {
            title: "duenio_agrupacion",
            mask: "Agrupacion",
            sortable: true,
          },
          {
            title: "duenio_delegacion",
            mask: "Delegacion",
            sortable: true,
          },
          {
            title: "duenio_estado_cliente",
            mask: "Socio",
            sortable: true,
          },
          {
            title: "inscribir",
            mask: "Acciones",
            sortable: false,
            html_insert: {
              tag: "inscribir_label",
            },
            actions: {
              inscribir_label: this.inscribir,
            },
          },
        ],
      },

      inscriptosTable: {
        options: {
          /// Go to OPTIONS SECTION for explanation
        },
        actions: [],
        headers: [
          {
            title: "can_nombre",
            mask: "Can Nombre",
            sortable: true,
            searchable: false,
          },
          {
            title: "can_codigo",
            mask: "Can Numero",
            sortable: true,
            searchable: true,
          },
          {
            title: "can_sexo",
            mask: "Can Sexo",
            sortable: true,
          },
          {
            title: "fecha_nacimiento",
            mask: "Fecha de Nacimiento",
            sortable: true,
          },
          {
            title: "can_categoria",
            mask: "Categoria",
            sortable: false,
          },
          {
            title: "can_peludo",
            mask: "Pelo Largo",
            sortable: true,
          },
          {
            title: "duenio",
            mask: "Dueño",
            sortable: true,
            searchable: false,
          },
          {
            title: "agrupacion",
            mask: "Agrupacion",
            sortable: true,
          },
          {
            title: "delegacion",
            mask: "Delegacion",
            sortable: true,
          },
          {
            title: "torneo",
            mask: "Torneo",
            sortable: true,
          },
          {
            title: "fecha",
            mask: "Fecha",
            sortable: false,
          },
          {
            title: "Baja",
            mask: "Acciones",
            sortable: false,
            html_insert: {
              tag: "bajar_label",
            },
            actions: {
              bajar_label: this.baja_can,
            },
          },
        ],
      },
    };
  },
  async mounted() {
    await this.prepareFilters();
    await this.findInscriptos();
  },
  computed: {
    ...mapGetters([
      "user",
      "getTorneos",
      "getCalendarios",
      "getPerros",
      "getUserInfo",
    ]),
    torneos() {
      return this.getTorneos;
    },
    calendarios() {
      return this.getCalendarios;
    },
    perros() {
      return this.$store.getters.getPerros;
    },
    perros_inscriptos() {
      return this.$store.getters.getInscriptos;
    },
  },
  methods: {
    ...mapActions(["baja", "inscribirCan"]),
    async prepareFilters() {
      await this.$store.dispatch("findTorneos");
      this.torneo = this.getTorneos[0].value;

      await this.$store.dispatch("findCalendarios", this.torneo);
      this.calendario_id = this.getCalendarios[0].value ??= null;
    },
    async get_calendarios(arg) {
      this.torneo = arg;
      await this.$store.dispatch("findCalendarios", arg);
      this.calendario_id = this.getCalendarios[0].value ??= null;
    },
    async updateCalendarios(arg) {
      this.calendario_id = arg;
    },
    async findPerros(data) {
      this.cached_search_inscribir = data ?? this.cached_search_inscribir;

      if (this.cached_search_inscribir == "") {
        this.cached_search_inscribir = {
          page: 1,
          per_page: 10,
          search: this.cached_search_inscribir.search,
        };
      }

      this.isFetchingInscribir = true;
      await this.$store.dispatch("findPerros", {
        data: data ?? data ?? this.cached_search_inscribir,
        user: this.user,
        calendario: this.calendario_id,
      });
      this.isFetchingInscribir = false;
    },
    async findInscriptos(data) {
      this.cached_search_inscriptos = data ?? this.cached_search_inscriptos;

      if (this.cached_search_inscriptos == "") {
        this.cached_search_inscriptos = {
          page: 1,
          per_page: 10,
          search: this.cached_search_inscriptos.search,
        };
      }

      this.isFetchingInscriptos = true;
      await this.$store.dispatch("findInscriptos", {
        data: data ?? this.cached_search_inscriptos,
        user: this.user,
      });
      this.isFetchingInscriptos = false;
    },
    async inscribir(data) {
      if (data.inscribir) {
        data.user = this.user;
        //await this.$store.dispatch("inscribirCan", data);
        await this.inscribirCan(data);
        this.refresh_tables();
      }
    },
    async baja_can(arg) {
      try {
        arg.torneo = this.torneo;
        arg.calendario = this.calendario_id;
        await this.baja(arg);
        this.refresh_tables();
      } catch (error) {
        console.log(error);
      }
    },

    refresh_tables() {
      this.findPerros();
      this.findInscriptos();
    },
  },
};
</script>

<style lang="scss">
.contenedor-selectores {
  display: flex;

  & > .selector {
    width: 50% !important;
  }
}

td,
tr {
  text-align: center;
}

.marg-b-2 {
  border-bottom: 1px solid white;
  padding-bottom: 4em;
  margin-bottom: 2em;
}
</style>